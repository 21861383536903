<template>
  <div>
     <span :class="`border border-dark ${this.className} darken-2 rounded-lg py-2 px-3 white--text text-body-1`">
       {{taskStatus}}
     </span>
  </div>

</template>

<script>


import {getTaskDelayStatus, STATUS_DELAY} from "./common";

export default {
  name: "taskStatus",

  props: ['task'],

  computed: {

    delayStatus() {
      return this.getTaskDelayStatus(this.task)
    },

    className() {
      switch (this.delayStatus) {
        case STATUS_DELAY.PENDING:
        case STATUS_DELAY.DELAYED:
          return 'red'
        default:
          return 'green'
      }
    },
    taskStatus() {
      switch (this.delayStatus) {
        case STATUS_DELAY.PENDING:
        case STATUS_DELAY.DELAYED:
          return 'Con Retraso'
        default:
          return 'A Tiempo'
      }


    },
  },
  data: function() {
    return {
    }
  },

  watch: {},

  components: {},

  methods: {
    getTaskDelayStatus,
  }
}
</script>

<style scoped>
</style>