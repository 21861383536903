<template>

  <div>
    <v-dialog
        v-if="this.deletedId > 0"
        scrollable
        v-model="showDeleteEvent"
        max-width="300px"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Eliminar evento</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="d-flex justify-left">
              <v-radio-group v-model="deleteStatus" column>
                <v-radio label="Esta Tarea" value="0"></v-radio>
                <v-radio label="Todas las Tareas" value="1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteEvent">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="isDeleting"
              @click="onDeleteEvent"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        scrollable
        v-model="showEditRecurring"
        max-width="350px"
        persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Editar evento recurrente</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="d-flex justify-left">
              <v-radio-group v-model="recurringStatus" column>
                <v-radio label="Esta Tarea" value="0"></v-radio>
                <v-radio
                    label="Esta Tareas y las siguientes"
                    value="1"
                ></v-radio>
                <v-radio label="Todas las Tareas" value="2"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditRecurringEvent">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="isRecurrentSaving"
              @click="onSaveForRecurrency"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        scrollable
        v-model="showCustomRecurrence"
        max-width="400px"
        persistent
    >
      <v-form ref="form_custom_recurrence" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h6">Recurrencia personalizada</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row class="align-center">
                <v-col cols="12" sm="4" md="4" lg="4" class="mr-1">
                  <span>Repite cada</span>
                </v-col>
                <v-col cols="12" sm="2" md="2" lg="2" class="d-flex p-0 mr-4">
                  <v-text-field
                      v-model="customRecurrence.interval"
                      type="number"
                      class="text-left"
                      @change="onChangeRecurrenceInterval"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3" class="d-flex p-0">
                  <v-select
                      v-model="customRecurrence.freq"
                      :items="FREQ_ITEMS"
                      item-text="text"
                      item-value="value"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="customRecurrence.freq === 'days'">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-radio-group
                      v-model="customRecurrence.isWeekend"
                      class="d-flex flex-row"
                  >
                    <v-radio label="Entre semana" value="1" row></v-radio>
                    <v-radio label="Todos los dias" value="2" row></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row
                  v-else-if="customRecurrence.freq === 'weeks'"
                  class="align-center"
              >
                <v-col cols="12" sm="4" md="4" lg="4">
                  <span>Repetir en</span>
                </v-col>
                <v-col cols="12" sm="8" md="8" lg="8" class="p-0">
                  <v-select
                      v-model="customRecurrence.workingDays"
                      :items="WORKINGDAYS_ITEMS"
                      item-text="text"
                      item-value="value"
                      multiple
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="closeCustomRecurrenceDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="onSaveCustomRecurrence(customRecurrence)"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
        v-if="task"
        scrollable
        v-model="showEdit"
        max-width="700px"
        persistent
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      v-model="task.name"
                      v-bind:label="`Nombre`"
                      required
                      :rules="requiredRules"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="text-right"
                    v-if="editedIndex !== -1 && editedIndex"
                >
                  <task-status :task="task"></task-status>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                      v-model="task.description"
                      clear-icon="mdi-close-circle"
                      label="Descripción"
                      row-height="15"
                      rows="5"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="task?.static">
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <v-file-input
                      show-size
                      v-bind:label="`${$t('Upload')} ${$t('PDF File')}`"
                      @change="onChangePDFFile"
                      v-model="task.static.file"
                      accept=".pdf"
                      required
                      :rules="[fileRules]"
                  >
                    <template v-if="task.static.static" #label>
                      {{ task.static.static.file }}
                    </template>
                  </v-file-input>
                  <v-btn
                      v-if="task.static.static"
                      color="blue darken-1"
                      class="mb-2"
                      @click="downloadPDF(task.static.static)"
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                    {{ `${$t("Download")} ${$t("PDF File")}` }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                      v-model="task.responsibleId"
                      v-bind:label="`Responsable`"
                      :item-text="formatName"
                      item-value="id"
                      :items="user_list"
                      clearable
                      required
                      :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                      v-model="task.brandId"
                      v-bind:label="`Marca`"
                      item-text="name"
                      item-value="id"
                      :items="brand_list"
                      :disabled="disableBrandEdit"
                      clearable
                      required
                      :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                      v-model="task.projectId"
                      v-bind:label="`Proyecto`"
                      item-text="name"
                      item-value="id"
                      :disabled="disableProjectEdit"
                      :items="project_list"
                      @change="onChangedProject"
                      clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                      v-model="task.dependencyId"
                      v-bind:label="`Dependencia`"
                      item-text="name"
                      item-value="id"
                      :loading="isDependenceLoading"
                      :items="dependence_list"
                      clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                      v-model="task.type"
                      v-bind:label="`Tipo tarea`"
                      :items="TASK_TYPES"
                      item-text="text"
                      item-value="value"
                      clearable
                      required
                      :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    v-if="task.type === 'Recurrente'"
                >
                  <v-text-field
                      :value="getRecurrenceDescription(task.recurrence)"
                      v-bind:label="`Recurrencia`"
                      :append-outer-icon="'mdi-send'"
                      @click:append-outer="openCustomRecurrenceDialog"
                      filled
                      readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="task.type === 'Recurrente'">
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                      ref="recurrence_startDate_menu"
                      v-model="select_recurrence_startDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="task.recurrence_startDate"
                          v-bind:label="`Fecha de inicio de recurrencia`"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          readonly
                          required
                          :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="task.recurrence_startDate"
                        no-title
                        @input="select_recurrence_startDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        required
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                      ref="recurrence_endDate_menu"
                      v-model="select_recurrence_endDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="task.recurrence_endDate"
                          v-bind:label="
                            `Fecha de finalización de la recurrencia`
                          "
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="task.recurrence_endDate"
                        no-title
                        scrollable
                        @input="select_recurrence_endDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        required
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                      v-model="task.priority"
                      v-bind:label="`Prioridad`"
                      :items="PRIORITY_TYPES"
                      item-text="text"
                      item-value="value"
                      clearable
                      required
                      :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                      v-model="task.status"
                      v-bind:label="`Estado`"
                      :items="STATUS_TYPES"
                      item-text="text"
                      item-value="value"
                      clearable
                      required
                      :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-radio-group
                      v-bind:label="`Tarea Comercial?`"
                      v-model="task.isCommercial"
                      row
                      required
                      :rules="requiredRules"
                  >

                    <v-radio label="Si" :value=true></v-radio>
                    <v-radio label="No" :value=false></v-radio>
                  </v-radio-group>

                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-menu
                      ref="task_startDate_menu"
                      v-model="select_task_startDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="task.task_startDate"
                          v-bind:label="$t('brands.start_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          readonly
                          :rules="taskStartDateRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="task.task_startDate"
                        no-title
                        @input="select_task_startDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-menu
                      ref="task_endDate_menu"
                      v-model="select_task_endDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="task.task_endDate"
                          v-bind:label="$t('brands.end_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="taskEndDateRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="task.task_endDate"
                        no-title
                        scrollable
                        @input="select_task_endDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        required
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                      v-model="task.hour"
                      v-bind:label="`Hora`"
                      type="time"
                      clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                text
                @click="onDeleteClick(task)"
                v-if="editedIndex !== -1 && editedIndex"
            >
              {{ $t("delete") }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="onSaveClick(task)"
                :loading="isSaving"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

    </v-dialog>



  </div>

</template>

<script>
import ApiService from "@/services/apiService";
import taskStatus from "../common/taskStatus";
import download from "js-file-download";
import {
  PRIORITY_TYPES,
  STATUS_TYPES,
  TASK_TYPES,
  FREQ_ITEMS,
  WORKINGDAYS_ITEMS,
} from "./common";
import {logError, logInfo, logWarning, requiredRules} from "../../../utils";
import _ from "lodash";
import moment from "moment/moment";
import {mapGetters} from "vuex";


export default {
  name: "editTask",

  props: [
    'value', //v-model
    'opts'
  ],

  data: function() {
    return {

      task: null,

      brand_list: [],
      user_list: [],
      project_list: [],
      dependence_list: [],

      PRIORITY_TYPES,
      TASK_TYPES,
      STATUS_TYPES,
      FREQ_ITEMS,
      WORKINGDAYS_ITEMS,

      valid: true,
      editedIndex: -1,
      /************************ Delete Dialog *************************/
      showDeleteEvent: false,
      deleteStatus: "0",
      isDeleting: false,
      deletedId: -1,
      /****************************************************************/

      /************************* Custom Recurrence *******************************/
      showCustomRecurrence: false,
      customRecurrence: {},


      isDependenceLoading: false,

      requiredRules,
      fileRules: file => {
        if (file) {
          if (file.size <= 5e6) {
            return true;
          } else {
            return "¡El tamaño del archivo debe ser inferior a 1 MB!";
          }
        }
        return true;
      },
      select_task_startDate_menu: false,
      select_task_endDate_menu: false,

      select_recurrence_startDate_menu: false,
      select_recurrence_endDate_menu: false,

      isSaving: false,
      showEditRecurring: false,
      isRecurrentSaving: false,
      bodyData: null,
      recurringStatus: "0",


    }
  },

  watch: {
    async opts(val) {
      await this.init()
      await this.initializeModel(val)
    }
  },

  components: {
    taskStatus
  },

  computed:{
    ...mapGetters("auth", ["user"]),
    showEdit: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    formTitle() {
      return this.isNewTask
          ? this.$t("newitem")
          : this.$t("edititem");
    },

    disableProjectEdit() {
      return !this.isNewTask
    },

    disableBrandEdit() {
      return !this.isNewTask || (this.task && !!this.task.projectId)
    },
    taskStartDateRules() {
      return this.task.projectId ? [v => !!v || "Required"] : [];
    },
    taskEndDateRules() {
      return this.task.type === "Puntual" || this.task.projectId
          ? [v => !!v || "Required"]
          : [];
    },

    isNewTask() {
      return this.editedIndex === -1 || this.editedIndex === undefined
    },

  },

  methods: {

    async init() {
      const { data } = await ApiService.get('tasks/opts');
      this.brand_list = data.brand_list
      this.user_list = data.user_list
      this.project_list = data.project_list
    },


    createModel(item) {
      return {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        brandId: _.get(item, "brandId"),
        dependencyId: _.get(item, "dependencyId"),
        docFile: _.get(item, "docFile"),
        hour: _.get(item, "hour"),
        priority: _.get(item, "priority"),
        projectId: _.get(item, "projectId"),
        recurrence: _.get(item, "recurrence"),
        status: _.get(item, "status"),
        isCommercial: _.get(item, "isCommercial"),
        _status: _.get(item, "status"),
        type: _.get(item, "type"),
        date: _.get(item, "date"),
        task_startDate: _.get(item, "task_startDate"),
        task_endDate: _.get(item, "task_endDate"),
        recurrence_startDate: _.get(item, "recurrence_startDate"),
        recurrence_endDate: _.get(item, "recurrence_endDate"),
        responsibleId: _.get(item, "responsibleId", this.user.id),
        static: {
          file: null,
          static: _.get(item, "static")
        },
        completedDate: _.get(item, "completedDate")
      };
    },

    async initializeModel(opts) {
      const model = this.createModel(opts)
      this.editedIndex = model.id;
      this.task = model;
      if (model.projectId){
        await this.onChangedProject(model.projectId);
      }
    },

    async onChangedProject(projectId) {
      const project = this.project_list.find(el=>el.id === projectId)

      this.dependence_list = [];
      if (!projectId) return;
      let fetch_url = `tasks/getTasks/${projectId}`;
      this.isDependenceLoading = true;

      const resp = await ApiService.get(fetch_url);
      this.dependence_list = resp.data;
      this.dependence_list = this.dependence_list.filter(
          el => el.id !== this.task.id
      );

      this.task.task_startDate = project.startDate
      this.task.task_endDate = project.endDate

      this.task.brandId = this.project_list.find(el=>el.id === projectId).brandId
      this.isDependenceLoading = false;
    },

    closeDeleteEvent() {
      this.showDeleteEvent = false;
      this.$nextTick(() => {
        this.deleteStatus = "0";
        this.deletedId = -1;
      });
    },

    async onChangePDFFile(file) {
      this.task.static.file = file;
    },

    getTextFromFreq(freq) {
      const result = this.FREQ_ITEMS.filter(el => el.value === freq);
      return result[0].text;
    },

    getRecurrenceDescription(recurrence) {
      if (!recurrence) return "";

      let description = "Cada ";
      const frequencyText = this.getTextFromFreq(recurrence.freq);

      if (recurrence.interval === "1") {
        const sliceIndex = recurrence.freq === "months" ? -2 : -1;
        description += frequencyText.slice(0, sliceIndex);
      } else {
        description += `${recurrence.interval} ${frequencyText}`;
      }

      if (recurrence.freq === "days" && recurrence.isWeekend === "1") {
        description += " durante los días laborables";
      } else if (
          recurrence.freq === "weeks" &&
          recurrence.workingDays?.length
      ) {
        const workingDaysText = this.getTextFromWorkingDays(
            recurrence.workingDays
        );
        description += ` en ${workingDaysText.join(", ")}`;
      }

      return description;
    },

    async downloadPDF(document) {
      this.show();
      let file_uri = ApiService.getFileUri(document ? document : null);
      if (file_uri !== null) {
        try {
          const response = await ApiService.get(file_uri, {
            responseType: "arraybuffer"
          });
          download(response, document.file);
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },

    getTextFromWorkingDays(workingDays) {
      let data_list = [];
      this.WORKINGDAYS_ITEMS.forEach(el => {
        if (workingDays.includes(el.value)) data_list.push(el.text);
      });
      return data_list;
    },


    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        if (item.task_startDate && item.task_endDate) {
          if (item.task_startDate > item.task_endDate) {
            logError(this.$t("Error") + " - " + this.$t("brands.start_date"));
            return;
          }
        }
        if (item.projectId) {
          const project_date = this.getProjectDate(item.projectId);
          if (
              item.task_startDate < project_date.startDate ||
              item.task_startDate > project_date.endDate
          ) {
            logError(
                "No puede iniciar la tarea antes de la fecha de inicio del proyecto ni después de la fecha de finalización del proyecto."
            );
            return;
          }
        }
        if (item.type === "Recurrente") {
          if (
              (item.task_startDate &&
                  item.task_startDate < item.recurrence_startDate) ||
              (item.task_endDate && item.task_endDate > item.recurrence_endDate)
          ) {
            if(!item.id) { //new item
              logError(
                  "La fecha de inicio y la fecha de finalización de la tarea deben estar entre la fecha de inicio y la fecha de finalización de la recurrencia."
              );
              return;
            }else{
              logWarning(
                  "La fecha de inicio y la fecha de finalización de la tarea no estan entre la fecha de inicio y la fecha de finalización de la recurrencia."
              );
            }
          }
        }
        if (item.recurrence_startDate && item.recurrence_endDate) {
          if (item.recurrence_startDate > item.recurrence_endDate) {
            logError(this.$t("Error") + " - " + this.$t("brands.start_date"));
            return;
          }
        }
        if (
            item.type === "Recurrente" &&
            !Object.keys(item.recurrence).length
        ) {
          logError("Recurrencia inválida");
          return;
        }
        let body = _.omit(item, ["static"]);
        try {
          this.isSaving = true;
          let file = item.static.file;
          if (file) {
            let { data } = await ApiService.uploadFile(file);
            body.docFile = data.id;
          }
          let local_date = moment(new Date())
              .local()
              .format("YYYY-MM-DD");
          body = {
            ...body,
            assignedBy: this.user.id,
            assignmentDate: local_date
          };
          let completedDate = null;
          if (body.status === "Finalizada") {
            if (body._status !== body.status) completedDate = local_date;
            else completedDate = body.completedDate;
          }
          body = {
            ...body,
            completedDate
          };
          if (body.type !== "Recurrente") {
            body["recurrence"] = undefined;
          }else{ // task is recurrent
            body.date = body.date || body.task_endDate // FIX RECURRENT EDIT MODAL NOT SHOWING WHEN EDIT TASK CALLED OUT OF CALENDAR VIEW
          }
          if (body.hour === "") body.hour = undefined;
          if (body.id) {
            if (body.type === "Recurrente" && body.date) {
              this.showEditRecurring = true;
              this.bodyData = body;
              this.isSaving = false;
              this.recurringStatus = "0";
              return;
            }
            const resp = await ApiService.put(`tasks/${body.id}`, body);
            if (resp.success) {
              logInfo("tareas actualizada");
            } else {
              logError(resp.message);
            }
          } else {
            const resp = await ApiService.post(`tasks`, body);
            if (resp.success) {
              logInfo("tareas creadas");
            } else {
              logError(resp.message);
            }
          }
          this.showEdit = false;
          this.isSaving = false;
          this.$emit('onChange', this.task)
        } catch (error) {
          this.showEdit = false;
          this.isSaving = false;
        }
      }
    },

    async onDeleteEvent() {
      this.isDeleting = true;
      if (window.confirm("¿Estás seguro de que deseas eliminar este evento?")) {
        try {
          let fetch_url = `tasks/${this.deletedId}/${this.deleteStatus}/${this.task.date}`;
          await ApiService.delete(fetch_url);
          logInfo("Success");
          this.isDeleting = false;
          this.showDeleteEvent = false;
          this.showEdit = false;
          this.$emit('onChange', this.task)
        } catch (error) {
          logError(error);
          this.isDeleting = false;
          this.showDeleteEvent = false;
        }
      }
    },

    async onDeleteClick(item) {
      if (item.type === "Recurrente") {
        this.deletedId = item.id;
        this.showDeleteEvent = true;
        this.deleteStatus = "0";
        return;
      }
      if (window.confirm("¿Estás seguro de que deseas eliminar este evento?")) {
        try {
          this.isDeleting = true;
          let fetch_url = `tasks/${item.id}/-1/-1`;
          await ApiService.delete(fetch_url);
          logInfo("Success");
          this.isDeleting = false;
          this.showEdit = false;
          this.$emit('onChange', this.task)
        } catch (error) {
          console.log(error);
          logError(error);
          this.isDeleting = false;
        }
      }
    },

    async onSaveCustomRecurrence(item) {
      if (this.$refs.form_custom_recurrence.validate()) {
        this.task.recurrence = {
          freq: item.freq,
          interval: item.interval
        };
        if (item.freq === "days") {
          this.task.recurrence = {
            ...this.task.recurrence,
            isWeekend: item.isWeekend
          };
        }
        if (item.freq === "weeks") {
          this.task.recurrence = {
            ...this.task.recurrence,
            workingDays: [...item.workingDays]
          };
        }
        this.showCustomRecurrence = false;
      }
    },

    async onSaveForRecurrency() {
      try {
        this.isRecurrentSaving = true;
        let body = {
          ...this.bodyData,
          recurringStatus: this.recurringStatus
        };
        await ApiService.put(`tasks/${body.id}`, body);
        this.showEdit = false;
        this.showEditRecurring = false;
        this.isRecurrentSaving = false;
        this.$emit('onChange', this.task)
      } catch (error) {
        this.showEdit = false;
        this.showEditRecurring = false;
        this.isRecurrentSaving = false;
      }
    },

    closeEditDialog() {
      this.$emit('input', false)
      this.$nextTick(() => {
        this.task = null;
        this.editedIndex = -1;
      });
    },

    closeEditRecurringEvent() {
      this.showEditRecurring = false;
      this.$nextTick(() => {
        this.bodyData = null;
      });
    },

    closeCustomRecurrenceDialog() {
      this.showCustomRecurrence = false;
      this.$nextTick(() => {
        this.customRecurrence = {};
      });
    },

    onChangeRecurrenceInterval() {
      let val = parseInt(this.customRecurrence.interval);
      if (val <= 0) this.customRecurrence.interval = "1";
    },

    openCustomRecurrenceDialog() {
      this.customRecurrence = {
        freq: "days",
        interval: "1",
        workingDays: [],
        isWeekend: "1",
        day: "1",
        month: "1"
      };
      if (this.task.recurrence) {
        this.customRecurrence = {
          ...this.customRecurrence,
          ...this.task.recurrence
        };
      }
      this.showCustomRecurrence = true;
    },

    getProjectDate(projectId) {
      let project_date = { startDate: null, endDate: null };
      this.project_list.forEach(el => {
        if (el.id === projectId) {
          project_date = { startDate: el.startDate, endDate: el.endDate };
        }
      });
      return project_date;
    },

    formatName(item) {
      return  [item.name,item.surname].filter(el=>!!el).join(' ')
    }

  },

  async mounted() {
    await this.init();
  },

}
</script>

<style scoped>
</style>