import moment from "moment/moment";

export const STATUS_DELAY = {
    COMPLETED: "Completed",
    PENDING: "Pending",
    DELAYED: "Delayed",
    OVERDUE: "Overdue"
}

export const STATUS_STYLES = {
    COMPLETED: "forestgreen",
    PENDING: "#777272",
    DELAYED: "red",
    OVERDUE: "forestgreen"
}

export const STATUS_TYPES = [
    { text: "Finalizada", value: "Finalizada" },
    { text: "Pendiente", value: "Pendiente" }
];

export const TASK_TYPES = [
    { text: "Puntual", value: "Puntual" },
    { text: "Recurrente", value: "Recurrente" }
];

export const TEMPLATE_TYPES = [
    { text: "Inicio Proyecto", value: "PROJECT_START" },
    { text: "Aumento GPV's", value: "GPVINCREASE" },
    { text: "Nuevo Canal", value: "NEWCHANNEL" },
    { text: "Otros", value: "OTHERS" }
];

export const PRIORITY_TYPES = [
    { text: "Alta", value: "Alta" },
    { text: "Media", value: "Media" },
    { text: "Baja", value: "Baja" }
];

export const FREQ_ITEMS = [
    { text: "días", value: "days" },
    { text: "semanas", value: "weeks" },
    { text: "meses", value: "months" },
    { text: "años", value: "years" }
]

export const WORKINGDAYS_ITEMS = [
    { text: "Domingo", value: "Sun" },
    { text: "Lunes", value: "Mon" },
    { text: "Martes", value: "Tue" },
    { text: "Miercoles", value: "Wed" },
    { text: "Jueves", value: "Thu" },
    { text: "Viernes", value: "Fri" },
    { text: "Sabado", value: "Sat" }
]


export const  isTaskOnTime = (task) => {
    const {status, completedDate, task_endDate } = task;
    let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
    if (status === "Finalizada") return completedDate <= task_endDate;
    return today <= task_endDate;
};

export const  getTaskDelayStatus = (task) => {
    let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
    if (
        task.status === "Finalizada" &&
        task.completedDate <= task.task_endDate
    )
        return STATUS_DELAY.COMPLETED;
    if (task.status === "Pendiente" && today <= task.task_endDate)
        return STATUS_DELAY.PENDING;
    if (task.status === "Pendiente" && today > task.task_endDate)
        return STATUS_DELAY.DELAYED;
    if (
        task.status === "Finalizada" &&
        task.completedDate > task.task_endDate
    )
        return STATUS_DELAY.OVERDUE;
};



export const getStyleFromStatus = (task) => {

    const { status } = task;

    let styleType = null;
    if (isTaskOnTime(task)) {
        if (status === "Finalizada") styleType = STATUS_STYLES.COMPLETED;
        else styleType = STATUS_STYLES.PENDING;
    } else {
        if (status === "Finalizada") styleType = STATUS_STYLES.OVERDUE;
        else styleType = STATUS_STYLES.DELAYED;
    }
    return `background-color: ${styleType}`;
};